@font-face {
	font-family:'ModernEra';
	src: url("../../public/fonts/ModernEra-Regular_gdi.woff2") format("woff2"),
      url("../../public/fonts/ModernEra-Regular_gdi.woff") format("woff"),
      url("../../public/fonts/ModernEra-Regular_gdi.ttf") format("ttf");
	font-weight: 400;
	font-style: normal;
	font-stretch: normal;
	unicode-range: U+0020-2122;
}

@font-face {
	font-family:'ModernEra';
	src: url("../../public/fonts/ModernEra-Medium_gdi.woff2") format("woff2"),
      url("../../public/fonts/ModernEra-Medium_gdi.woff") format("woff"),
      url("../../public/fonts/ModernEra-Medium_gdi.ttf") format("ttf");
	font-weight: 500;
	font-style: normal;
	font-stretch: normal;
	unicode-range: U+0020-2122;
}